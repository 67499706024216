import React, { useMemo, useEffect, FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import { Assessment } from 'typings/assessment';
import { actions as targetGroupsActions } from 'store/targetGroups/targetGroups.actions';
import { AddIcon } from 'evergreen-ui';
import { actions as assessmentsActions } from 'store/assessments/assessments.actions';
import AppButton from 'components/AppButton/AppButton';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { selectTargetGroupState } from 'store/targetGroups/targetGroups.selectors';
import { selectAssessmentsState } from 'store/assessments/assessments.selectors';
import { selectAuthState } from 'store/auth/auth.selectors';
import { Company } from 'store/companies/companies.reducer';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import ScansCardsList from '../../components/ScansCardsList/ScansCardsList';

interface ScansListViewProps extends AuthenticatedUserProps {}

const ScansListView: FunctionComponent<ScansListViewProps> = () => {
    const { companies, loadingCompanies } = useSelector(selectCompaniesState);
    const targetGroupsFromStore = useSelector(selectTargetGroupState);
    const assessments = useSelector(selectAssessmentsState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companySlug } = useParams();
    const { user } = useSelector(selectAuthState);

    const currentCompany = companies.find(
        (company: Company) => company.slug === companySlug,
    );

    const scansAndGroups: { scan: Assessment; groupsCount: number }[] =
        useMemo(() => {
            if (
                currentCompany &&
                currentCompany.id &&
                assessments.byCompanyId[currentCompany.id] &&
                assessments.byCompanyId[currentCompany.id].list
            ) {
                return assessments.byCompanyId[currentCompany.id].list.map(
                    (a) => ({
                        scan: a,
                        groupsCount:
                            targetGroupsFromStore.byAssessmentId[a.id] &&
                            targetGroupsFromStore.byAssessmentId[a.id].list
                                ? targetGroupsFromStore.byAssessmentId[a.id]
                                      .list.length
                                : 0,
                    }),
                );
            }
            return [];
        }, [
            assessments.byCompanyId,
            currentCompany,
            targetGroupsFromStore.byAssessmentId,
        ]);

    const renderCreateNewTemplateButton = () => {
        return (
            <AppButton icon={AddIcon} onClick={() => navigate('new')}>
                Create new scan
            </AppButton>
        );
    };

    useEffect(() => {
        if (
            currentCompany &&
            currentCompany.id &&
            assessments.byCompanyId[currentCompany.id] &&
            assessments.byCompanyId[currentCompany.id].list
        ) {
            assessments.byCompanyId[currentCompany.id].list.forEach(
                (assessment) => {
                    dispatch(
                        targetGroupsActions.getAssessmentsTargetGroups(
                            currentCompany.id,
                            assessment.id,
                        ),
                    );
                },
            );
        }
    }, [assessments.byCompanyId, currentCompany, dispatch]);

    useEffect(() => {
        if (user && user.company_id && currentCompany) {
            dispatch(
                assessmentsActions.getCompanyAssessments(
                    currentCompany.id,
                    true,
                ),
            );
        }
    }, [user, currentCompany, dispatch]);

    if (currentCompany && !loadingCompanies) {
        return (
            <div className={styles.scan_template_view}>
                <div className={styles.scan_template_info_box}>
                    <div className={styles.scan_template_info_box__info}>
                        <div className={styles.scan_template_info_box__name}>
                            <h1>Scans</h1>
                        </div>
                    </div>
                    <div
                        className={
                            styles.scan_template_info_box__add_target_group
                        }>
                        <div className={styles.button}>
                            {renderCreateNewTemplateButton()}
                        </div>
                    </div>
                </div>
                {companySlug && (
                    <ScansCardsList
                        isScanSetupFlow={false}
                        companySlug={companySlug}
                        scansAndGroups={scansAndGroups}
                    />
                )}
            </div>
        );
    }
    if (!loadingCompanies && !currentCompany) {
        return (
            <div className={styles.no_company_error}>
                <h1>Company Not Found</h1>
                <h3>Please check the URL.</h3>
            </div>
        );
    } else {
        return <ProgressSpinner />;
    }
};

export default ScansListView;
