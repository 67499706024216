import { User } from '../../typings/user';

export const actionsTypes = {
    GET_TARGET_GROUP_USERS: 'USERS/GET_TARGET_GROUP_USERS',
    GET_TARGET_GROUP_USERS_SUCCESS: 'USERS/GET_TARGET_GROUP_USERS_SUCCESS',
    GET_TARGET_GROUP_USERS_FAILURE: 'USERS/GET_TARGET_GROUP_USERS_FAILURE',
    REMOVE_PARTICIPANT: 'USERS/REMOVE_PARTICIPANT',
    GET_USER_ACTIVITY: 'USERS/GET_USER_ACTIVITY',
    GET_USER_ACTIVITY_SUCCESS: 'USERS/GET_USER_ACTIVITY_SUCCESS',
    GET_USER_ACTIVITY_FAILURE: 'USERS/GET_USER_ACTIVITY_FAILURE',
    GET_USER_EMAIL_INFO: 'USERS/GET_USER_EMAIL_INFO',
    GET_USER_EMAIL_INFO_SUCCESS: 'USERS/GET_USER_EMAIL_INFO_SUCCESS',
    GET_USER_EMAIL_INFO_FAILURE: 'USERS/GET_USER_EMAIL_INFO_FAILURE',
    CONVERT_USER_VIDEOS: 'USERS/CONVERT_USER_VIDEOS',
    CONVERT_USER_VIDEOS_SUCCESS: 'USERS/CONVERT_USER_VIDEOS_SUCCESS',
    CONVERT_USER_VIDEOS_FAILURE: 'USERS/CONVERT_USER_VIDEOS_FAILURE',
    SEND_EMAIL: 'USERS/SEND_EMAIL',
    SEND_EMAIL_SUCCESS: 'USERS/SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILURE: 'USERS/SEND_EMAIL_FAILURE',
    GET_CANDIDATE: 'USERS/GET_CANDIDATE',
    GET_CANDIDATE_SUCCESS: 'USERS/GET_CANDIDATE_SUCCESS',
    GET_CANDIDATE_FAILURE: 'USERS/GET_CANDIDATE_FAILURE',
    GET_ALL_USERS_INVITATIONS_IN_GROUP: 'GET_ALL_USERS_INVITATIONS_IN_GROUP',
    GET_ALL_USERS_INVITATIONS_IN_GROUP_SUCCESS:
        'GET_ALL_USERS_INVITATIONS_IN_GROUP_SUCCESS',
    GET_ALL_USERS_INVITATIONS_IN_GROUP_FAILURE:
        'GET_ALL_USERS_INVITATIONS_IN_GROUP_FAILURE',
};

export const actions = {
    getTargetGroupUsers: (
        companyIdentifier: string | undefined,
        assessmentId: string,
        groupId: string,
        page: number,
        candidatesPerPage: number,
    ) => ({
        type: actionsTypes.GET_TARGET_GROUP_USERS,
        payload: { companyIdentifier, assessmentId, groupId, page, candidatesPerPage },
    }),
    getAllUsersInvitationsInGroup: (companyIdentifier: string | undefined, groupId: string, users: User[]) => ({
        type: actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP,
        payload: { companyIdentifier, groupId, users },
    }),
    removeParticipant: (groupId: string, userId: string) => ({
        type: actionsTypes.REMOVE_PARTICIPANT,
        payload: { groupId, userId },
    }),
    getUserActivity: (
        companyIdentifier: string | undefined,
        groupId: string,
        userId: string,
        includeReportUrl: boolean,
    ) => ({
        type: actionsTypes.GET_USER_ACTIVITY,
        payload: { companyIdentifier, groupId, userId, includeReportUrl },
    }),
    getUserEmailInfo: (companyIdentifier: string | undefined, groupId: string, userId: string) => ({
        type: actionsTypes.GET_USER_EMAIL_INFO,
        payload: { companyIdentifier, groupId, userId },
    }),
    convertUserVideos: (companyId: string, groupId: string, userId: string) => ({
        type: actionsTypes.CONVERT_USER_VIDEOS,
        payload: { companyId, groupId, userId },
    }),
    sendEmail: (
        companyId: string,
        groupId: string,
        userId: string,
        email: string,
    ) => ({
        type: actionsTypes.SEND_EMAIL,
        payload: { companyId, groupId, userId, email },
    }),
    getCandidate: (candidateId: string, groupId: string) => ({
        type: actionsTypes.GET_CANDIDATE,
        payload: { candidateId, groupId },
    }),
};
