import { actionsTypes } from './targetGroups.actions';
import {
    FeedbackList,
    TargetGroup,
    TargetGroupParticipant,
    WorkPreferenceBaselineData,
} from 'typings/target_group';
import { CreateUserSchema } from '../../typings/user';

interface TargetGroupById {
    [groupId: string]: {
        data: TargetGroup;
        updatingConfig: boolean;
        updatingParticipants: boolean;
        participantsToRemove: TargetGroupParticipant[];
        participantsToAdd: CreateUserSchema[];
        error: any;
        workPreferenceBaseline?: WorkPreferenceBaselineData;
        feedback?: FeedbackList;
        updatingFeedback: boolean;
        resetCandidateScan: boolean;
        resetScanLoading: boolean;
    };
}

export interface TargetGroupsState {
    byAssessmentId: {
        [assessmentId: string]: {
            list: TargetGroup[];
            totalCount: number;
            isFetching: boolean;
        };
    };
    byId: TargetGroupById;
    fetching: boolean;
    error: any;
    sendingInvitations: boolean;
    sendingReminders: boolean;
    sendingReports: boolean;
    reportsNotSent: string[];
    creatingTargetGroup: boolean;
    deletingTargetGroup: boolean;
}
export const targetGroupsInitialState = {
    byAssessmentId: {},
    byId: {},
    fetching: false,
    error: null,
    sendingInvitations: false,
    sendingReminders: false,
    sendingReports: false,
    reportsNotSent: [],
    creatingTargetGroup: false,
    deletingTargetGroup: false,
};

const targetGroupsReducer = (
    state: TargetGroupsState = targetGroupsInitialState,
    action: { type: string; payload: any },
): TargetGroupsState => {
    switch (action.type) {
        case actionsTypes.GET_COMPANY_GROUPS:
            return {
                ...state,
                fetching: true,
                error: null,
            };
        case actionsTypes.GET_COMPANY_GROUPS_SUCCESS: {
            const { groups } = action.payload;
            const targetGroupsById: TargetGroupById = {};
            const targetGroupsByAssessmentId: {
                [assessmentId: string]: {
                    list: TargetGroup[];
                    totalCount: number;
                    isFetching: boolean;
                };
            } = {};
            groups.forEach((targetGroup: TargetGroup) => {
                const existingAssessmentGroupList = targetGroupsByAssessmentId[
                    targetGroup.assessment_id
                ]
                    ? targetGroupsByAssessmentId[
                          targetGroup.assessment_id
                      ].list.filter((grp) => grp.id !== targetGroup.id)
                    : [];
                const newGroupsList = [
                    ...existingAssessmentGroupList,
                    targetGroup,
                ];

                targetGroupsByAssessmentId[targetGroup.assessment_id] = {
                    list: newGroupsList,
                    totalCount: newGroupsList.length,
                    isFetching: false,
                };
                targetGroupsById[targetGroup.id] = {
                    ...state.byId[targetGroup.id],
                    data: targetGroup,
                    updatingConfig: false,
                    updatingParticipants: false,
                    participantsToRemove: [],
                    participantsToAdd: [],
                    resetCandidateScan: false,
                    resetScanLoading: false,
                    error: null,
                };
            });

            return {
                ...state,
                byAssessmentId: targetGroupsByAssessmentId,
                byId: targetGroupsById,
                fetching: false,
                error: null,
            };
        }
        case actionsTypes.GET_TARGET_GROUP_BY_ID:
        case actionsTypes.GET_ASSESSMENT_TARGET_GROUPS: {
            const { assessmentId } = action.payload;
            return {
                ...state,
                byAssessmentId: {
                    ...state.byAssessmentId,
                    [assessmentId]: {
                        ...state.byAssessmentId[assessmentId],
                        isFetching: true,
                    },
                },
                fetching: true,
                error: null,
            };
        }
        case actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_SUCCESS: {
            const { assessmentId, targetGroups } = action.payload;
            const targetGroupsById = { ...state.byId };
            targetGroups.forEach((targetGroup: TargetGroup) => {
                targetGroupsById[targetGroup.id] = {
                    ...state.byId[targetGroup.id],
                    data: targetGroup,
                    updatingConfig: false,
                    updatingParticipants: false,
                    participantsToRemove: [],
                    participantsToAdd: [],
                    resetCandidateScan: false,
                    resetScanLoading: false,
                    error: null,
                };
            });
            return {
                ...state,
                byAssessmentId: {
                    ...state.byAssessmentId,
                    [assessmentId]: {
                        list: targetGroups,
                        totalCount: targetGroups.length,
                        isFetching: false,
                    },
                },
                byId: targetGroupsById,
                fetching: false,
                error: null,
            };
        }
        case actionsTypes.GET_TARGET_GROUP_BY_ID_SUCCESS: {
            const { targetGroup } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [targetGroup.id]: {
                        ...state.byId[targetGroup.id],
                        data: targetGroup,
                        updatingConfig: false,
                        updatingParticipants: false,
                        participantsToRemove: [],
                        participantsToAdd: [],
                        resetCandidateScan: false,
                        resetScanLoading: false,
                        error: null,
                    },
                },
                error: null,
            };
        }
        case actionsTypes.GET_WORK_PREFERENCE_BASELINE_SUCCESS: {
            const { groupId, workPreferenceBaseline } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        workPreferenceBaseline,
                    },
                },
            };
        }
        case actionsTypes.GET_TARGET_GROUP_FEEDBACK: {
            const { groupId } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingFeedback: true,
                    },
                },
            };
        }
        case actionsTypes.GET_TARGET_GROUP_FEEDBACK_SUCCESS: {
            const { groupId, feedback } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        feedback,
                        updatingFeedback: false,
                    },
                },
            };
        }
        case actionsTypes.GET_TARGET_GROUP_FEEDBACK_FAILURE: {
            const { groupId } = action.payload;

            return {
                ...state,
                error: action.payload.error,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingFeedback: false,
                    },
                },
            };
        }
        case actionsTypes.RESET_CANDIDATE_SCAN: {
            const { groupId } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        resetScanLoading: true,
                        resetCandidateScan: false,
                    },
                },
            };
        }
        case actionsTypes.RESET_CANDIDATE_SCAN_SUCCESS: {
            const { groupId } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        resetScanLoading: false,
                        resetCandidateScan: true,
                    },
                },
            };
        }
        case actionsTypes.RESET_CANDIDATE_SCAN_FAILURE: {
            const { groupId } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        resetScanLoading: false,
                        resetCandidateScan: false,
                    },
                },
            };
        }
        case actionsTypes.GET_TARGET_GROUP_BY_ID_FAILURE:
        case actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }
        case actionsTypes.FULL_DELETE_PARTICIPANTS:
        case actionsTypes.REMOVE_PARTICIPANTS: {
            const { groupId, participants } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingParticipants: true,
                        participantsToRemove: participants,
                    },
                },
                error: null,
            };
        }

        case actionsTypes.ADD_PARTICIPANTS: {
            const { groupId, participants } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingParticipants: true,
                        participantsToAdd: participants,
                    },
                },
                error: null,
            };
        }

        case actionsTypes.REMOVE_PARTICIPANTS_SUCCESS:
        case actionsTypes.FULL_DELETE_PARTICIPANTS_SUCCESS:
        case actionsTypes.ADD_PARTICIPANTS_SUCCESS: {
            const { groupId, targetGroup } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        data: targetGroup,
                        updatingParticipants: false,
                        participantsToRemove: [],
                        participantsToAdd: [],
                        error: null,
                    },
                },
                error: null,
            };
        }

        case actionsTypes.REMOVE_PARTICIPANTS_FAILURE:
        case actionsTypes.FULL_DELETE_PARTICIPANTS_FAILURE:
        case actionsTypes.ADD_PARTICIPANTS_FAILURE: {
            const { groupId, error } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingParticipants: false,
                        participantsToRemove: [],
                        participantsToAdd: [],
                        error,
                    },
                },
                error: null,
            };
        }

        case actionsTypes.UPDATE_TARGET_GROUP_CONFIG: {
            const { groupId } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingConfig: true,
                    },
                },
                error: null,
            };
        }
        case actionsTypes.UPDATE_TARGET_GROUP_CONFIG_SUCCESS: {
            const { groupId, targetGroup } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        data: targetGroup,
                        updatingConfig: false,
                        updatingParticipants: false,
                        participantsToRemove: [],
                        participantsToAdd: [],
                        error: null,
                    },
                },
                error: null,
            };
        }
        case actionsTypes.UPDATE_TARGET_GROUP_CONFIG_FAILURE: {
            const { groupId, error } = action.payload;
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [groupId]: {
                        ...state.byId[groupId],
                        updatingConfig: false,
                        error,
                    },
                },
                error: null,
            };
        }

        case actionsTypes.CREATE_TARGET_GROUP: {
            return {
                ...state,
                creatingTargetGroup: true,
                error: null,
            };
        }
        case actionsTypes.CREATE_TARGET_GROUP_SUCCESS: {
            const { targetGroup } = action.payload;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [targetGroup.id]: {
                        data: targetGroup,
                        updatingConfig: false,
                        updatingParticipants: false,
                        participantsToRemove: [],
                        participantsToAdd: [],
                        error: null,
                    },
                },
                creatingTargetGroup: false,
                error: null,
            };
        }
        case actionsTypes.CREATE_TARGET_GROUP_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                creatingTargetGroup: false,
                error: error,
            };
        }
        case actionsTypes.DELETE_TARGET_GROUP: {
            return {
                ...state,
                deletingTargetGroup: true,
                error: null,
            };
        }
        case actionsTypes.DELETE_TARGET_GROUP_SUCCESS: {
            const { groupId } = action.payload;
            let nextState = { ...state.byId };
            delete nextState[groupId];
            return {
                ...state,
                byId: nextState,
                deletingTargetGroup: false,
                error: null,
            };
        }
        case actionsTypes.DELETE_TARGET_GROUP_FAILURE: {
            const { error } = action.payload;
            return {
                ...state,
                deletingTargetGroup: false,
                error: error,
            };
        }

        default:
            return state;
    }
};

export default targetGroupsReducer;
