import { actionsTypes } from './dashboardUsers.actions';
import { DashboardUser } from "../../typings/dashboard_user";

export interface DashboardUsersState {
  creatingDashboardUser: boolean;
  dashboardUsers: {
    [companyId: string]: DashboardUser[];
  };
  error: any;
  loading: boolean;
}

export const dashboardUsersInitialState = {
  creatingDashboardUser: false,
  dashboardUsers: {},
  error: false,
  loading: false
};

const dashboardUsersReducer = (state: DashboardUsersState = dashboardUsersInitialState, action: { type: string; payload: any; }) => {
  switch (action.type) {
    case actionsTypes.CREATE_DASHBOARD_USER: {
      return {
        ...state,
        creatingDashboardUser: true,
        loading: true
      };
    }
    case actionsTypes.CREATE_DASHBOARD_USER_SUCCESS: {
      return {
        ...state,
        creatingDashboardUser: false,
        loading: false
      };
    }
    case actionsTypes.CREATE_DASHBOARD_USER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        creatingDashboardUser: false,
        loading: false,
        error: error
      };
    }
    case actionsTypes.REMOVE_RECRUITER:
    case actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS: {
      return {
        ...state,
        loading: true
      };
    }
    case actionsTypes.REMOVE_RECRUITER_SUCCESS:{
      return {
        ...state,
        loading: false
      };
    }
    case actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS: {
      const { users, companyId } = action.payload;
      return {
        ...state,
        dashboardUsers: {
          ...state.dashboardUsers,
          [companyId]: users
        },
        loading: false
      };
    }

    case actionsTypes.REMOVE_RECRUITER_FAILURE:
    case actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error: error
      };
    }
    default:
      return state;
  }
};

export default dashboardUsersReducer;