import React, { FunctionComponent, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppButton from 'components/AppButton/AppButton';
import { CSVLink } from 'react-csv';
import { DownloadIcon } from 'evergreen-ui';
import { TargetGroupFeedbackDownloadProps } from '../../../typings';
import {
    selectTargetGroupFeedback,
    selectTargetGroupFeedbackUpdating,
} from '../../../store/targetGroups/targetGroups.selectors';
import { actions as targetGroupActions } from '../../../store/targetGroups/targetGroups.actions';
import { FeedbackList } from '../../../typings/target_group';
import CircularProgress from '@material-ui/core/CircularProgress';

const TargetGroupFeedbackDownload: FunctionComponent<
    TargetGroupFeedbackDownloadProps
> = (props) => {
    const {
        companyId,
        companyName,
        groupId,
        assessmentName,
        assessmentLanguage,
        targetGroupName,
    } = props;

    const dispatch = useDispatch();

    const csvLink = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const feedbackList: FeedbackList =
        useSelector(selectTargetGroupFeedback(groupId)) || {};
    const feedbackUpdating =
        useSelector(selectTargetGroupFeedbackUpdating(groupId)) || false;

    const experienceQuestion =
        assessmentLanguage === 'nl-NL'
            ? 'Beschrijf hoe je ervaring was tijdens het uitvoeren van deze scan.'
            : 'Please describe your experience taking this scan.';
    const suggestionsQuestion =
        assessmentLanguage === 'nl-NL'
            ? 'Is er nog iets dat je met ons of ' +
              companyName +
              ' zou willen delen?'
            : 'Is there anything you want to share with us or ' +
              companyName +
              '?';
    const feedbackRate =
        assessmentLanguage === 'nl-NL'
            ? 'Hoe zou je je ervaring tijdens het uitvoeren van de scan beoordelen?'
            : 'How would you rate your experience doing this scan?';

    const feedbackCsvData = Object.keys(feedbackList).map((userId) => ({
        'Scan name': assessmentName,
        'Group name': targetGroupName,
        'Candidate ID': userId,
        [experienceQuestion]: feedbackList[userId].experienceDescription
            ? feedbackList[userId].experienceDescription
            : '-',
        [suggestionsQuestion]: feedbackList[userId].improveSuggestion
            ? feedbackList[userId].improveSuggestion
            : '-',
        [feedbackRate]: feedbackList[userId].feedbackRate
            ? feedbackList[userId].feedbackRate
            : '-',
    }));

    const downloadFeedback = () => {
        dispatch(
            targetGroupActions.getTargetGroupFeedback(companyId, groupId),
        );
        setIsSaving(true);
    };

    useEffect(() => {
        if (!feedbackUpdating && feedbackCsvData.length && isSaving) {
            csvLink?.current?.link.click();
            setIsSaving(false);
        }
    }, [feedbackUpdating, feedbackCsvData.length, isSaving]);

    return (
        <div aria-label="download feedback button">
            <AppButton
                justifyContent={'flex-start'}
                width={'auto'}
                icon={DownloadIcon}
                isDisabled={feedbackUpdating || isSaving}
                onClick={() => downloadFeedback()}>
                {feedbackUpdating && (
                    <CircularProgress
                        size={24}
                        style={{
                            color: '#fff',
                            marginTop: '4px',
                            marginRight: '100px',
                        }}
                    />
                )}
                {!feedbackUpdating && 'Download feedback'}
            </AppButton>
            {!!feedbackCsvData &&
                !!feedbackCsvData.length &&
                !feedbackUpdating && (
                    <CSVLink
                        data={feedbackCsvData}
                        filename={
                            companyName +
                            '-' +
                            targetGroupName +
                            '-feedback.csv'
                        }
                        className="hidden"
                        ref={csvLink}
                        target={'_blank'}
                    />
                )}
        </div>
    );
};

export default TargetGroupFeedbackDownload;
