import React, { useState, useEffect, FunctionComponent, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import { GroupType, TargetGroup } from 'typings/target_group';
import { Assessment } from 'typings/assessment';
import { actions as targetGroupsActions } from 'store/targetGroups/targetGroups.actions';
import { UserIcon, AddIcon } from 'evergreen-ui';
import { actions as assessmentsActions } from 'store/assessments/assessments.actions';
import AppButton from 'components/AppButton/AppButton';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import styled from 'styled-components';
import * as variables from 'assets/styles/variables';
import { selectTargetGroupState } from 'store/targetGroups/targetGroups.selectors';
import { selectAssessmentsState } from 'store/assessments/assessments.selectors';
import { selectAuthState } from 'store/auth/auth.selectors';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import CardsView, { LabelObject } from '../../components/CardsView/CardsView';
import { CompanyBase } from '../../typings/company';

interface CompanyViewProps extends AuthenticatedUserProps {
    groupsType: GroupType;
}

const MonospaceText = styled.code`
    font-size: 12px;
    margin-top: 12px;
    background-color: #f6fcfd;
    padding: 2px 4px;
    border: 1px solid ${variables.borderColor};
    border-radius: 3px;
    cursor: pointer;
`;

const CompanyView: FunctionComponent<CompanyViewProps> = (props) => {
    const { userRoles, groupsType } = props;
    const isSuperAdmin = userRoles.includes('super-admin');
    const hasCompanyAdminRole = userRoles.includes('company-admin');

    const { companies, loadingCompanies } = useSelector(selectCompaniesState);
    const targetGroupsFromStore = useSelector(selectTargetGroupState);
    const assessments = useSelector(selectAssessmentsState);
    const [company, setCompany] = useState<CompanyBase | null>(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { companySlug } = useParams();

    const { user } = useSelector(selectAuthState);

    useEffect(() => {
        if (companies.length > 0) {
            const company = companies.find(
                (company) => company.slug === companySlug,
            );
            if (company) {
                setCompany(company);
            }
        }
    }, [companies, companySlug]);

    const scansAndGroups: { scan: Assessment; activeGroups: TargetGroup[] }[] =
        useMemo(() => {
            if (
                company &&
                company.id &&
                assessments.byCompanyId[company.id] &&
                assessments.byCompanyId[company.id].list
            ) {
                return assessments.byCompanyId[company.id].list.map((a) => ({
                    scan: a,
                    activeGroups:
                        targetGroupsFromStore.byAssessmentId[a.id] &&
                        targetGroupsFromStore.byAssessmentId[a.id].list
                            ? targetGroupsFromStore.byAssessmentId[
                                  a.id
                              ].list.filter((tg) => {
                                  return (
                                      a.id === tg.assessment_id &&
                                      a.is_active &&
                                      company.id === a.company_id &&
                                      tg.type === groupsType
                                  );
                              })
                            : [],
                }));
            }
            return [];
        }, [
            assessments.byCompanyId,
            company,
            targetGroupsFromStore.byAssessmentId,
            groupsType,
        ]);

    const renderManageTeamButton = () => {
        return (
            <AppButton
                icon={UserIcon}
                onClick={() => navigate(`/${companySlug}/manage-team`)}>
                Manage Team
            </AppButton>
        );
    };

    const renderSetupScanButton = () => {
        return (
            <AppButton icon={AddIcon} onClick={() => navigate('new')}>
                {groupsType === 'INTERNAL'
                    ? 'Add Internal Group'
                    : 'Add Candidate Group'}
            </AppButton>
        );
    };

    useEffect(() => {
        if (company && company.id) {
            dispatch(
                targetGroupsActions.getCompanyGroups(company.id, groupsType),
            );
        }
    }, [company, groupsType, dispatch]);

    useEffect(() => {
        if (user && user.company_id && company) {
            dispatch(assessmentsActions.getCompanyAssessments(company.id, true));
        }
    }, [user, company, dispatch]);

    if (company && !loadingCompanies) {
        return (
            <div className={styles.target_groups_view}>
                <div className={styles.target_groups_info_box}>
                    <div className={styles.target_groups_info_box__info}>
                        <div className={styles.target_groups_info_box__name}>
                            <h3
                                className={
                                    styles.target_groups_info_box__company_name
                                }>
                                {company && company.name}
                            </h3>
                            {groupsType === 'INTERNAL' ? (
                                <h2>Internal groups</h2>
                            ) : (
                                <h2>Candidate groups</h2>
                            )}
                            {isSuperAdmin && company && (
                                <MonospaceText
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            company && company.id,
                                        );
                                    }}>
                                    {company && company.id}
                                </MonospaceText>
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            styles.target_groups_info_box__add_target_group
                        }>
                        {user && (hasCompanyAdminRole || isSuperAdmin) && (
                            <div className={styles.button}>
                                {renderManageTeamButton()}
                            </div>
                        )}
                        <>
                            <div className={styles.button}>
                                {renderSetupScanButton()}
                            </div>
                        </>
                    </div>
                </div>
                <div className={styles.card_list}>
                    <>
                        {!targetGroupsFromStore.fetching &&
                            scansAndGroups.length === 0 && (
                                <div>
                                    <em>There are no groups yet.</em>
                                </div>
                            )}
                        {!targetGroupsFromStore.fetching &&
                            !!scansAndGroups.length &&
                            scansAndGroups.map((scanAndGroups) => {
                                const { scan, activeGroups } = scanAndGroups;
                                return activeGroups.map((targetGroup) => {
                                    const numberParticipants =
                                        targetGroup.participants.length;

                                    const subtitle =
                                        numberParticipants === 1 &&
                                        groupsType === 'CANDIDATE'
                                            ? `1 candidate`
                                            : numberParticipants === 1 &&
                                              groupsType === 'INTERNAL'
                                            ? `1 participant`
                                            : numberParticipants !== 1 &&
                                              groupsType === 'INTERNAL'
                                            ? `${numberParticipants} participants`
                                            : `${numberParticipants} candidates`;

                                    const cardLabels: LabelObject[] = [];

                                    cardLabels.push({
                                        color: 'secondary',
                                        text: scan.language,
                                    });
                                    return (
                                        <Link
                                            key={targetGroup.id}
                                            to={`/${companySlug}/groups/${targetGroup.id}`}>
                                            <CardsView labels={cardLabels}>
                                                <h1>{targetGroup.name}</h1>
                                                <h2>{subtitle}</h2>
                                            </CardsView>
                                        </Link>
                                    );
                                });
                            })}
                    </>
                </div>
                {targetGroupsFromStore.fetching && <ProgressSpinner />}
            </div>
        );
    }
    if (!loadingCompanies && !company) {
        return (
            <div className={styles.no_company_error}>
                <h1>Company Not Found</h1>
                <h3>Please check the URL.</h3>
            </div>
        );
    } else {
        return <ProgressSpinner />;
    }
};

export default CompanyView;
