import * as React from 'react';
import styles from './TopbarNavigation.module.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { LogOutIcon, HomeIcon } from 'evergreen-ui';

import {
    IconButton,
    Menu,
    Avatar,
    MenuItem,
    ListItemIcon,
    Divider,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import { selectAuthState } from 'store/auth/auth.selectors';
import { selectCompaniesState } from '../../store/companies/companies.selectors';
import { Company } from '../../store/companies/companies.reducer';
import { useEffect, useState } from 'react';
import { selectAssessmentsById } from '../../store/assessments/assessments.selectors';
import { UserRole } from 'typings/user';
import { selectTargetGroupDataById } from '../../store/targetGroups/targetGroups.selectors';

interface TopbarProps {
    onLogout: () => void;
    userRoles: UserRole[];
}

const TopbarNavigation: React.FC<TopbarProps> = (props) => {
    const { onLogout } = props;
    const { user } = useSelector(selectAuthState);
    const { companySlug, assessmentId, groupId } = useParams();
    const { companies } = useSelector(selectCompaniesState);
    const assessments = useSelector(selectAssessmentsById);
    const group = useSelector(selectTargetGroupDataById(groupId));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pathname } = useLocation();
    const open = Boolean(anchorEl);
    const [companyId, setCompanyId] = useState<string>('');
    const [groupType, setGroupType] = useState<string>('');

    const currentCompany = companies.find(
        (company: Company) => company.id === companyId,
    );
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (
            assessmentId &&
            assessments &&
            assessments[assessmentId] &&
            assessments[assessmentId].data.company_id
        ) {
            setCompanyId(assessments[assessmentId].data.company_id);
        }
        if (groupId && group && group.type) {
            setGroupType(group.type);
        }

        // handle group type on pages without groupId in params
        if (!groupId) {
            setGroupType('');
        }
    }, [assessmentId, assessments, group, groupId]);

    return (
        <div className={styles.topbar}>
            <ul>
                <div className={styles.topbarUser_nav}>
                    <li className={styles.topbarNeurolytics_logo}>
                        <Link to="/">
                            <img
                                src="/neurolytics-logo.png"
                                alt="Neurolytics Logo"
                            />
                        </Link>
                    </li>
                    {user && pathname !== '/' && pathname !== '/add-company' && (
                        <nav>
                            <ul>
                                <li>
                                    <Link
                                        to={`/${
                                            companySlug
                                                ? companySlug
                                                : currentCompany?.slug
                                        }`}
                                        className={
                                            pathname ===
                                                `/${
                                                    companySlug
                                                        ? companySlug
                                                        : currentCompany?.slug
                                                }` ||
                                            (groupId &&
                                                pathname.includes(
                                                    groupId,
                                                ) &&
                                                groupType === 'CANDIDATE') ||
                                            pathname ===
                                                `/${
                                                    companySlug
                                                        ? companySlug
                                                        : currentCompany?.slug
                                                }/new`
                                                ? styles.topbarUser_active
                                                : styles.topbarUser_link
                                        }>
                                        Candidate groups
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={`/${
                                            companySlug
                                                ? companySlug
                                                : currentCompany?.slug
                                        }/internal`}
                                        className={
                                            pathname ===
                                                `/${
                                                    companySlug
                                                        ? companySlug
                                                        : currentCompany?.slug
                                                }/internal` ||
                                            (groupId &&
                                                pathname.includes(
                                                    groupId,
                                                ) &&
                                                groupType === 'INTERNAL') ||
                                            pathname ===
                                                `/${
                                                    companySlug
                                                        ? companySlug
                                                        : currentCompany?.slug
                                                }/internal/new`
                                                ? styles.topbarUser_active
                                                : styles.topbarUser_link
                                        }>
                                        Internal groups
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    )}
                </div>

                {user && (
                    <>
                        {(pathname === '/' || pathname === '/add-company') && (
                            <li className={styles.topbarHome_icon}>
                                <Link to="/">
                                    <HomeIcon size={35} color={'disabled'} />
                                </Link>
                            </li>
                        )}
                        <div className={styles.topbarLeft}>
                            {pathname !== '/' && pathname !== '/add-company' && (
                                <li>
                                    <Link
                                        to={`/${
                                            companySlug
                                                ? companySlug
                                                : currentCompany?.slug
                                        }/scans`}
                                        className={
                                            pathname.includes(
                                                `/${
                                                    companySlug
                                                        ? companySlug
                                                        : currentCompany?.slug
                                                }/scans`,
                                            )
                                                ? styles.topbarUser_active
                                                : styles.topbarUser_link
                                        }>
                                        Scans
                                    </Link>
                                </li>
                            )}
                            <li className={styles.topbarUser_menu}>
                                {/* @ts-ignore */}
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}>
                                    {/* @ts-ignore */}
                                    <Avatar sx={{ width: 32, height: 32 }}>
                                        {user && user.picture && (
                                            <img
                                                src={user.picture}
                                                alt="User avatar"
                                            />
                                        )}
                                    </Avatar>
                                </IconButton>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    disableScrollLock={true}
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        //@ts-ignore
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform:
                                                    'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            {/* @ts-ignore */}
                                            <Avatar
                                                sx={{ width: 15, height: 15 }}>
                                                {user && user.picture && (
                                                    <img
                                                        src={user.picture}
                                                        alt="User avatar"
                                                    />
                                                )}
                                            </Avatar>
                                        </ListItemIcon>
                                        {user.given_name && user.family_name
                                            ? user.given_name +
                                              ' ' +
                                              user.family_name
                                            : user.nickname}
                                    </MenuItem>
                                    <Divider />

                                    <MenuItem onClick={onLogout}>
                                        <ListItemIcon>
                                            <LogOutIcon size={20} />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </li>
                        </div>
                    </>
                )}
            </ul>
        </div>
    );
};

export default TopbarNavigation;
