import { call, put, delay, take } from 'redux-saga/effects';
import NeurolyticsAPI from 'services/NeurolyticsAPI';
import {
    actions,
    actionsTypes,
} from 'store/dashboardUsers/dashboardUsers.actions';

export function* createDashboardUser(action: { type: string; payload: any }) {
    const { companyId, dashboardUser } = action.payload;
    try {
        const { response } = yield call(
            [NeurolyticsAPI, 'createDashboardUserApi'],
            companyId,
            dashboardUser,
        );
        yield delay(2000);
        yield put(actions.getAllDashboardUsers(companyId));
        yield take(actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS);
        yield put({
            type: actionsTypes.CREATE_DASHBOARD_USER_SUCCESS,
            payload: { user: response, companyId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_DASHBOARD_USER_FAILURE,
            payload: { error },
        });
    }
}

export function* getAllDashboardUsers(action: { type: string; payload: any }) {
    const { companyId } = action.payload;
    try {
        const { response } = yield call(
            [NeurolyticsAPI, 'getAllDashboardUsersApi'],
            companyId,
        );
        yield put({
            type: actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS,
            payload: { users: response, companyId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_FAILURE,
            payload: { error },
        });
    }
}

export function* deleteRecruiterUserEffect(action: {
    type: string;
    payload: any;
}) {
    try {
        const { companyId, userId } = action.payload;
        const { response } = yield call(
            [NeurolyticsAPI, 'deleteRecruiterUserApi'],
            companyId,
            userId,
        );
        yield delay(2000);
        yield put(actions.getAllDashboardUsers(companyId));
        yield take(actionsTypes.GET_ALL_COMPANY_DASHBOARD_USERS_SUCCESS);
        yield put({
            type: actionsTypes.REMOVE_RECRUITER_SUCCESS,
            payload: { isRecruiterUserDeleted: response, companyId, userId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.REMOVE_RECRUITER_FAILURE,
            payload: { error },
        });
    }
}
