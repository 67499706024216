import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './hoc/WithAuthLayout/App.module.css';
import Login from 'components/Auth/Login/Login';
import TargetGroupView from 'views/TargetGroupView/TargetGroupView';
import ManageUsersView from 'views/ManageUsersView/ManageUsersView';
import CompanyView from 'views/CompanyView/CompanyView';
import Dashboard from 'views/Dashboard/Dashboard';
import AddCompanyView from 'views/AddCompanyView/AddCompanyView';
import ScanSetupView from 'views/ScanSetupView/ScanSetupView';
import ReportView from './views/ReportView/ReportView';
import WithAuthLayout from './hoc/WithAuthLayout';
import ScansListView from './views/ScansListView/ScansListView';
import ScansDetailsView from './views/ScanDetailsView/ScanDetailsView';

function App() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />

            <Route
                path="/"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <Dashboard />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <CompanyView groupsType="CANDIDATE" />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/scans"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ScansListView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/internal"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <CompanyView groupsType="INTERNAL" />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/scans/:assessmentId"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ScansDetailsView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/groups/:groupId"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <TargetGroupView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/assessments/:assessmentId/target-groups/:groupId"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <TargetGroupView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/groups/:groupId/results/:candidateId"
                element={
                    <WithAuthLayout addLayout={false}>
                        {/* @ts-ignore*/}
                        <ReportView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/assessments/:assessmentId/target-groups/:groupId/results/:candidateId"
                element={
                    <WithAuthLayout addLayout={false}>
                        {/* @ts-ignore*/}
                        <ReportView />
                    </WithAuthLayout>
                }
            />

            <Route
                path="/:companySlug/manage-team"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ManageUsersView />
                    </WithAuthLayout>
                }
            />
            <Route
                path="/add-company"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <AddCompanyView />
                    </WithAuthLayout>
                }
            />
            <Route
                path="/:companySlug/new"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ScanSetupView isGroupSetup={true} isInternal={false} />
                    </WithAuthLayout>
                }
            />
            <Route
                path="/:companySlug/internal/new"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ScanSetupView isGroupSetup={true} isInternal={true} />
                    </WithAuthLayout>
                }
            />
            <Route
                path="/:companySlug/scans/new"
                element={
                    <WithAuthLayout>
                        {/* @ts-ignore*/}
                        <ScanSetupView isGroupSetup={false} />
                    </WithAuthLayout>
                }
            />
        </Routes>
    );
}

export default App;
