import { call, put } from 'redux-saga/effects';
import { actionsTypes, actions } from './users.actions';
import NeurolyticsAPI from '../../services/NeurolyticsAPI';
import { User } from 'typings/user';
import { UserInvitation } from '../../typings/target_group';

export function* getTargetGroupUsers(action: { type: string; payload: any }) {
    try {
        const { companyIdentifier, assessmentId, groupId, page, candidatesPerPage } =
            action.payload;

        const { response } = yield call(
            NeurolyticsAPI.getTargetGroupUsers,
            companyIdentifier,
            groupId,
            assessmentId
        );
        const firstIndex = page * candidatesPerPage;
        const lastIndex = firstIndex + candidatesPerPage;
        const usersPerPage = response.slice(firstIndex, lastIndex);
        if (usersPerPage) {
            yield put(
                actions.getAllUsersInvitationsInGroup(
                    companyIdentifier,
                    groupId,
                    usersPerPage,
                ),
            );
        }
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_USERS_SUCCESS,
            payload: { companyIdentifier, assessmentId, groupId, users: usersPerPage, page },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_USERS_FAILURE,
            payload: { error },
        });
    }
}
export function* getAllUsersInvitationsInGroup(action: {
    type: string;
    payload: any;
}) {
    try {
        const { companyIdentifier, groupId, users } = action.payload;

        const response: UserInvitation = yield call(
            [NeurolyticsAPI, 'getAllInvitationsInGroupApi'],
            companyIdentifier,
            groupId,
        );
        yield put({
            type: actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_SUCCESS,
            payload: { companyIdentifier, groupId, users: users, invitations: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ALL_USERS_INVITATIONS_IN_GROUP_FAILURE,
            payload: { error },
        });
    }
}
export function* getCandidate(action: { type: string; payload: any }) {
    try {
        const { candidateId, groupId } = action.payload;

        const { response } = yield call(
            [NeurolyticsAPI, 'fetchCandidate'],
            candidateId,
        );
        yield put({
            type: actionsTypes.GET_CANDIDATE_SUCCESS,
            payload: { users: [response], groupId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_CANDIDATE_FAILURE,
            payload: { error },
        });
    }
}

export function* getTargetGroupUsersSuccess(action: {
    type: string;
    payload: any;
}) {
    const { companyIdentifier, groupId, users }: { companyIdentifier: string | undefined; groupId: string; users: User[] } =
        action.payload;

    for (let user of users) {
        yield put(actions.getUserActivity(companyIdentifier, groupId, user.id, false));
        yield put(actions.getUserEmailInfo(companyIdentifier, groupId, user.id));
    }
}

export function* getUserActivity(action: {
    type: string;
    payload: {
        companyIdentifier: string | undefined;
        groupId: string;
        userId: string;
        includeReportUrl: boolean;
    };
}) {
    try {
        const { companyIdentifier, groupId, userId, includeReportUrl } = action.payload;

        const { response } = yield call(
            [NeurolyticsAPI, 'getUserActivityApi'],
            companyIdentifier,
            groupId,
            userId,
            includeReportUrl,
        );
        yield put({
            type: actionsTypes.GET_USER_ACTIVITY_SUCCESS,
            payload: { groupId, userId, activity: response },
        });
    } catch (error) {
        const { groupId, userId } = action.payload;
        yield put({
            type: actionsTypes.GET_USER_ACTIVITY_FAILURE,
            payload: { groupId, userId, error },
        });
    }
}

export function* getUserEmailInfo(action: { type: string; payload: any }) {
    try {
        const { companyIdentifier, groupId, userId } = action.payload;
        const { response } = yield call(
            NeurolyticsAPI.getUserEmailInfo,
            companyIdentifier,
            groupId,
            userId,
        );

        yield put({
            type: actionsTypes.GET_USER_EMAIL_INFO_SUCCESS,
            payload: { groupId, userId, emails: response },
        });
    } catch (error) {
        const { groupId, userId } = action.payload;
        yield put({
            type: actionsTypes.GET_USER_EMAIL_INFO_FAILURE,
            payload: { groupId, userId, error },
        });
    }
}

export function* convertUserVideos(action: { type: string; payload: any }) {
    try {
        const { companyId, groupId, userId } = action.payload;
        yield call(NeurolyticsAPI.convertUserVideos, companyId, groupId, userId);
        yield put({
            type: actionsTypes.CONVERT_USER_VIDEOS_SUCCESS,
            payload: { groupId, userId },
        });
    } catch (error) {
        const { groupId, userId } = action.payload;
        yield put({
            type: actionsTypes.CONVERT_USER_VIDEOS_FAILURE,
            payload: { groupId, userId, error },
        });
    }
}

export function* sendEmail(action: { type: string; payload: any }) {
    const { companyId, groupId, userId, email } = action.payload;

    try {
        let response;
        if (email === 'reminder') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendReminderEmails,
                companyId,
                groupId,
                userId,
            );
        } else if (email === 'report') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendReportEmails,
                companyId,
                groupId,
                userId,
            );
        } else if (email === 'resetPassword') {
            // @ts-ignore
            response = yield call(
                NeurolyticsAPI.sendResetPasswordEmails,
                userId,
            );
        } else {
            yield put({
                type: actionsTypes.SEND_EMAIL_FAILURE,
                payload: { error: new Error('Missing email type') },
            });
        }
        yield put({
            type: actionsTypes.SEND_EMAIL_SUCCESS,
            payload: { groupId, userId, email, response },
        });

        yield put(actions.getUserEmailInfo(companyId, groupId, userId));
    } catch (error) {
        yield put({
            type: actionsTypes.SEND_EMAIL_FAILURE,
            payload: { groupId, userId, email, error },
        });
    }
}
