import { put, call } from 'redux-saga/effects';
import { actionsTypes } from './targetGroups.actions';
import { actions as UsersActions } from '../users/users.actions';

import NeurolyticsAPI from 'services/NeurolyticsAPI';
import {
    TargetGroup,
    TargetGroupParticipant,
    WorkPreferenceBaselineData,
    FeedbackList,
} from 'typings/target_group';

export function* getCompanyGroups(action: { type: string; payload: any }) {
    try {
        const { companyId, type } = action.payload;

        const groups: TargetGroup[] = yield call(
            [NeurolyticsAPI, 'getCompanyGroupsRequest'],
            companyId,
            type,
        );
        yield put({
            type: actionsTypes.GET_COMPANY_GROUPS_SUCCESS,
            payload: { groups },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_COMPANY_GROUPS_FAILURE,
            payload: { error },
        });
    }
}

export function* getAssessmentTargetGroups(action: {
    type: string;
    payload: any;
}) {
    try {
        const {companyIdentifier, assessmentId} = action.payload;

        const targetGroups: TargetGroup[] = yield call(
            NeurolyticsAPI.getAssessmentTargetGroups,
            companyIdentifier,
            assessmentId,
        );
        yield put({
            type: actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_SUCCESS,
            payload: { assessmentId, targetGroups },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_FAILURE,
            payload: { error },
        });
    }
}

export function* getTargetGroupById(action: { type: string; payload: any }) {
    try {
        const { companyIdentifier, groupId, assessmentId } = action.payload;

        const response: TargetGroup = yield call(
            NeurolyticsAPI.getTargetGroupById,
            companyIdentifier,
            groupId,
            assessmentId
        );
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_BY_ID_SUCCESS,
            payload: { companyIdentifier, groupId, targetGroup: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_BY_ID_FAILURE,
            payload: { error },
        });
    }
}

export function* getWorkPreferenceBaselineEffect(action: {
    type: string;
    payload: any;
}) {
    try {
        const { companyIdentifier, groupId, assessmentId } = action.payload;

        const baseline: WorkPreferenceBaselineData = yield call(
            [NeurolyticsAPI, 'getWorkPreferenceBaseline'],
            companyIdentifier,
            groupId,
            assessmentId
        );
        yield put({
            type: actionsTypes.GET_WORK_PREFERENCE_BASELINE_SUCCESS,
            payload: { groupId, workPreferenceBaseline: baseline },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_WORK_PREFERENCE_BASELINE_FAILURE,
            payload: { error },
        });
    }
}

export function* deleteEmptyTargetGroup(action: {
    type: string;
    payload: any;
}) {
    try {
        const { groupId, navigate, currentCompanySlug } =
            action.payload;

        yield call(
            NeurolyticsAPI.deleteEmptyTargetGroup,
            currentCompanySlug,
            groupId,
        );
        yield put({
            type: actionsTypes.DELETE_TARGET_GROUP_SUCCESS,
            payload: { currentCompanySlug, groupId },
        });
        navigate(`/${currentCompanySlug}`);
    } catch (error) {
        yield put({
            type: actionsTypes.DELETE_TARGET_GROUP_FAILURE,
            payload: { error },
        });
    }
}

export function* addParticipant(action: { type: string; payload: any }) {
    const { companyId, groupId, participants } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.addParticipantsToGroup,
            companyId,
            groupId,
            participants,
        );

        yield put({
            type: actionsTypes.ADD_PARTICIPANTS_SUCCESS,
            payload: {
                companyId,
                groupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.ADD_PARTICIPANTS_FAILURE,
            payload: { error, groupId },
        });
    }
}

export function* removeParticipant(action: { type: string; payload: any }) {
    const {
        companyId,
        groupId,
        participants,
    }: {
        companyId: string;
        groupId: string;
        participants: TargetGroupParticipant[];
    } = action.payload;

    try {
        const userIds: string[] = participants.map(
            (user) => user.user_id,
        );
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.removeParticipantsFromTargetGroup,
            companyId,
            groupId,
            userIds,
        );

        for (const participant of participants) {
            yield put(
                UsersActions.removeParticipant(
                    groupId,
                    participant.user_id,
                ),
            );
        }
        yield put({
            type: actionsTypes.REMOVE_PARTICIPANTS_SUCCESS,
            payload: {
                companyId,
                groupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.REMOVE_PARTICIPANTS_FAILURE,
            payload: { error, groupId },
        });
    }
}

export function* fullDeleteParticipant(action: { type: string; payload: any }) {
    const {
        companyId,
        groupId,
        participants,
    }: {
        companyId: string;
        groupId: string;
        participants: TargetGroupParticipant[];
    } = action.payload;

    try {
        const userIds: string[] = participants.map(
            (user) => user.user_id,
        );
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.fullDeleteParticipantsFromTargetGroup,
            companyId,
            groupId,
            userIds,
        );

        for (const participant of participants) {
            yield put(
                UsersActions.removeParticipant(
                    groupId,
                    participant.user_id,
                ),
            );
        }
        yield put({
            type: actionsTypes.FULL_DELETE_PARTICIPANTS_SUCCESS,
            payload: {
                companyId,
                groupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.FULL_DELETE_PARTICIPANTS_FAILURE,
            payload: { error, groupId },
        });
    }
}

export function* updateTargetGroupConfig(action: {
    type: string;
    payload: any;
}) {
    const { companySlug, groupId, data } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.updateTargetGroupConfig,
            companySlug,
            groupId,
            data,
        );
        yield put({
            type: actionsTypes.UPDATE_TARGET_GROUP_CONFIG_SUCCESS,
            payload: {
                companySlug,
                groupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.UPDATE_TARGET_GROUP_CONFIG_FAILURE,
            payload: { error, groupId },
        });
    }
}

export function* createTargetGroup(action: { type: string; payload: any }) {
    const { companyId, data } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.createTargetGroup,
            companyId,
            data,
        );

        yield put({
            type: actionsTypes.CREATE_TARGET_GROUP_SUCCESS,
            payload: { targetGroup: response.targetGroup },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_TARGET_GROUP_FAILURE,
            payload: { error, companyId },
        });
    }
}

export function* getTargetGroupFeedbackEffect(action: {
    type: string;
    payload: any;
}) {
    const { companyId, groupId } = action.payload;
    try {
        const response: FeedbackList = yield call(
            [NeurolyticsAPI, 'getTargetGroupFeedback'],
            companyId,
            groupId,
        );

        yield put({
            type: actionsTypes.GET_TARGET_GROUP_FEEDBACK_SUCCESS,
            payload: { groupId, feedback: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_FEEDBACK_FAILURE,
            payload: { groupId, error },
        });
    }
}

export function* resetCandidateScanEffect(action: {
    type: string;
    payload: any;
}) {
    const { companyIdentifier, groupId, userId } = action.payload;
    try {
        yield call(
            [NeurolyticsAPI, 'resetCandidateScan'],
            companyIdentifier,
            groupId,
            userId,
        );

        yield put(UsersActions.getUserActivity(companyIdentifier, groupId, userId, false));
        yield put(UsersActions.getUserEmailInfo(companyIdentifier, groupId, userId));

        yield put({
            type: actionsTypes.RESET_CANDIDATE_SCAN_SUCCESS,
            payload: { groupId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.RESET_CANDIDATE_SCAN_FAILURE,
            payload: { groupId },
        });
    }
}
