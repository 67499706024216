import { CreateUserSchema } from 'typings/user';
import {
    APIInvitationResponse,
    GroupType,
    TargetGroupUpdate,
    UpdateTargetGroupSchema,
    UserInvitation,
} from 'typings/target_group';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { CreateDashboardUserSchema } from 'typings/dashboard_user';
import {
    Assessment,
    AssessmentCreationConfig,
    AssessmentUpdate,
} from '../typings/assessment';
import { NEUROLYTICS_API } from 'utils/environment-variables';
import { auth0Client } from 'Auth';
import { Company } from '../store/companies/companies.reducer';
import { MediaFile } from '../typings/mediaFiles';

const getAuthToken = async () => {
    if (auth0Client) {
        try {
            return await auth0Client.getTokenSilently();
        } catch (error) {
            throw error;
        }
    } else {
        return null;
    }
};

const axiosConfig = async (
    endpoint: string,
    method: string,
    data: object,
    contentType: string,
    isPublic: boolean,
) => {
    const isNeurolyticsRequest = !endpoint.startsWith('http');
    try {
        let headers: {
            'Content-Type': string;
            Authorization?: string;
        } = {
            'Content-Type': contentType,
        };
        if (!isPublic) {
            const accessToken = await getAuthToken();
            if (accessToken) {
                headers = {
                    ...headers,
                    Authorization: `Bearer ${accessToken}`,
                };
            }
        }
        return {
            method,
            url: isNeurolyticsRequest
                ? `${NEUROLYTICS_API}/${endpoint}`
                : endpoint,
            headers,
            data,
        };
    } catch (error) {
        throw error;
    }
};

export const apiRequest = async (
    endpoint: any,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
    data: object = {},
    contentType: string = 'application/json',
    isPublic: boolean = false,
    returnRawResponse: boolean = false,
) => {
    const config: object = await axiosConfig(
        endpoint,
        method,
        data,
        contentType,
        isPublic,
    );
    const axiosResponse = await axios(config);
    return returnRawResponse ? axiosResponse : axiosResponse.data;
};

export class NeurolyticsAPI {
    async fetchCompanyAssessments(
        companyId: string,
        include_modules: boolean,
    ): Promise<Assessment[]> {
        try {
            const response = await apiRequest(
                `companies/${companyId}/assessments?include_modules=${include_modules}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response ? response : [];
        } catch (error) {
            const errorMessage = `fetchCompanyAssessments API Request failed using companyId: ${companyId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAssessmentApi(
        companyIdentifier: string | undefined,
        assessmentId: string,
    ): Promise<Assessment> {
        if (!companyIdentifier) {
            try {
                return await apiRequest(
                    `legacy/assessments/${assessmentId}`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
            } catch (error) {
                const errorMessage = `getAssessment API Request failed using assessmentId: ${assessmentId}. ${
                    error instanceof Error && error.message
                        ? error.message
                        : 'UnExpectedError'
                }.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            return await apiRequest(
                `companies/${companyIdentifier}/assessments/${assessmentId}`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getAssessment API Request failed using companyId: ${companyIdentifier} and assessmentId: ${assessmentId}. ${
                error instanceof Error && error.message
                    ? error.message
                    : 'UnExpectedError'
            }.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async updateAssessmentConfig(
        companyId: string,
        assessmentId: string,
        data: AssessmentUpdate,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/assessments/${assessmentId}/config`,
                'PUT',
                data,
                'application/json',
                false,
            );
            return {
                assessment: response,
            };
        } catch (error) {
            const errorMessage = `updateAssessmentConfig API Request failed using companyId: ${companyId} and assessmentId: ${assessmentId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async requestCreateAssessment(
        companyId: string,
        assessment: AssessmentCreationConfig,
    ): Promise<Assessment> {
        try {
            return await apiRequest(
                `companies/${companyId}/assessments`,
                'POST',
                assessment,
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `requestCreateAssessment API Request failed using companyId: ${companyId}. ${
                error instanceof Error && error.message
                    ? error.message
                    : 'UnExpectedError'
            }.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    private async getS3PreSignedUrl(
        companyId: string,
        params: {
            type: string;
            file_name: string;
            params?: { interview_video_number?: number };
        },
    ): Promise<{ url: string; id: string }> {
        return apiRequest(
            `companies/${companyId}/files/upload`,
            'POST',
            params,
            'application/json',
            false,
        );
    }

    async uploadVideoForScan(
        companyId: string,
        params: {
            type: string;
            file_name: string;
            params?: { interview_video_number?: number };
        },
        file: File,
    ): Promise<{
        url: string;
        id: string;
    }> {
        try {
            const presignedUrl = await this.getS3PreSignedUrl(
                companyId,
                params,
            );
            await apiRequest(presignedUrl.url, 'PUT', file, 'video/mp4', true);
            await apiRequest(
                `companies/${companyId}/files/${presignedUrl.id}`,
                'PATCH',
            );
            return {
                url: presignedUrl.url.split('?')[0],
                id: presignedUrl.id,
            };
        } catch (error) {
            const errorMessage = `requestUpload API Request failed when trying to upload: ${params}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanyGroupsRequest(companyId: string, type: GroupType) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups?type=${type}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response ? response : [];
        } catch (error) {
            const errorMessage = `getCompanyGroups API Request failed using companyId: ${companyId}, groups type: ${type}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAssessmentTargetGroups(
        companyIdentifier: string | undefined,
        assessmentId: string,
    ) {
        if (!companyIdentifier) {
            try {
                const response = await apiRequest(
                    `legacy/assessments/${assessmentId}/target_groups`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
                return response ? response : [];
            } catch (error) {
                const errorMessage = `getAssessmentTargetGroups API Request failed using assessmentId: ${assessmentId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups?type=ALL&assessment_id=${assessmentId}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return response ? response : [];
        } catch (error) {
            const errorMessage = `getAssessmentTargetGroups API Request failed using companyId=${companyIdentifier} and assessmentId: ${assessmentId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupById(
        companyIdentifier: string,
        groupId: string,
        assessmentId: string,
    ) {
        if (!companyIdentifier && assessmentId) {
            try {
                return await apiRequest(
                    `legacy/assessments/${assessmentId}/target_groups/${groupId}`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
            } catch (error) {
                const errorMessage = `getTargetGroupById API Request failed using assessmentId: ${assessmentId} and groupId: ${groupId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            return await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getTargetGroupById API Request failed using companyId: ${companyIdentifier} and groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAllInvitationsInGroupApi(
        companyIdentifier: string | undefined,
        groupId: string,
    ) {
        if (!companyIdentifier) {
            try {
                const response = await apiRequest(
                    `legacy/target_groups/${groupId}/invitations`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
                const invitations: UserInvitation[] = response?.map(
                    (invitation: APIInvitationResponse) => ({
                        user_id: invitation.user_id,
                        invitation_created_at: invitation.created_at,
                        status: invitation.status ? invitation.status : {},
                    }),
                );

                return invitations;
            } catch (error) {
                const errorMessage = `getAllInvitationsInGroup API Request failed using groupId: ${groupId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}/invitations`,
                'GET',
                {},
                'application/json',
                false,
            );
            const invitations: UserInvitation[] = response?.map(
                (invitation: APIInvitationResponse) => ({
                    user_id: invitation.user_id,
                    invitation_created_at: invitation.created_at,
                    status: invitation.status ? invitation.status : {},
                }),
            );
            return invitations;
        } catch (error) {
            const errorMessage = `getAllInvitationsInGroup API Request failed using companyIdentifier: ${companyIdentifier} and groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getWorkPreferenceBaseline(
        companyIdentifier: string | undefined,
        groupId: string,
        assessmentId: string | undefined,
    ) {
        if (!companyIdentifier && assessmentId) {
            try {
                return await apiRequest(
                    `legacy/assessments/${assessmentId}/target_groups/${groupId}/work-preference-baseline`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
            } catch (error) {
                const errorMessage = `getWorkPreferenceBaseline API Request failed using assessmentId: ${assessmentId} and groupId: ${groupId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            return await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}/work-preference-baseline`,
                'GET',
                {},
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `getWorkPreferenceBaseline API Request failed using companyId: ${companyIdentifier} and groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupUsers(
        companyIdentifier: string | undefined,
        groupId: string,
        assessmentId: string,
    ) {
        if (!companyIdentifier) {
            try {
                const response = await apiRequest(
                    `legacy/assessments/${assessmentId}/target_groups/${groupId}/participants`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
                return {
                    response: response,
                };
            } catch (error) {
                const errorMessage = `getTargetGroupUsers API Request failed using assessmentId: ${assessmentId} and groupId: ${groupId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}/users`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getTargetGroupUsers API Request failed using companyIdentifier: ${companyIdentifier} and groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteEmptyTargetGroup(companySlug: string, groupId: string) {
        try {
            return await apiRequest(
                `companies/${companySlug}/groups/${groupId}`,
                'DELETE',
            );
        } catch (error) {
            const errorMessage = `deleteEmptyTargetGroupById API Request failed using companySlug: ${companySlug} and groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteEmptyScan(companySlug: string, assessmentId: string) {
        try {
            return await apiRequest(
                `companies/${companySlug}/assessments/${assessmentId}`,
                'DELETE',
            );
        } catch (error) {
            const errorMessage = `deleteEmptyScan API Request failed using companySlug: ${companySlug} and assessmentId: ${assessmentId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fetchCandidate(candidateId: string) {
        try {
            const response = await apiRequest(
                `users/${candidateId}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `fetchCandidate API Request failed using candidateId: ${candidateId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getUserActivityApi(
        companyIdentifier: string | undefined,
        groupId: string,
        userId: string,
        includeReportUrl: boolean,
    ) {
        if (!companyIdentifier) {
            try {
                const response = await apiRequest(
                    `legacy/target_groups/${groupId}/users/${userId}/activity?include_report_url=${includeReportUrl}`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
                return {
                    response: response,
                };
            } catch (error) {
                const errorMessage = `getUserActivity API Request failed using groupId: ${groupId} and userId: ${userId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}/users/${userId}/activity?include_report_url=${includeReportUrl}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getUserActivity API Request failed using companyIdentifier: ${companyIdentifier} and groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getUserEmailInfo(
        companyIdentifier: string | undefined,
        groupId: string,
        userId: string,
    ) {
        if (!companyIdentifier) {
            try {
                const response = await apiRequest(
                    `legacy/target_groups/${groupId}/users/${userId}/emails_info`,
                    'GET',
                    {},
                    'application/json',
                    false,
                );
                return {
                    response: response,
                };
            } catch (error) {
                const errorMessage = `getUserEmailInfo API Request failed using groupId: ${groupId} and userId: ${userId}. ${error}.`;
                Sentry.captureMessage(errorMessage);
                throw Error(errorMessage);
            }
        }
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}}/emails/${userId}`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getUserEmailInfo API Request failed using companyIdentifier: ${companyIdentifier} and groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createDashboardUserApi(
        companyId: string,
        user: CreateDashboardUserSchema,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/users`,
                'POST',
                user,
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `createDashboardUser API Request failed for companyId=${companyId} with user: ${user}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getAllDashboardUsersApi(companyId: string) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/users`,
                'GET',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `getAllDashboardUsersApi API Request failed with company id: ${companyId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async deleteRecruiterUserApi(companyId: string, userId: string) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/users/${userId}`,
                'DELETE',
                {},
                'application/json',
                false,
            );
            return {
                response,
            };
        } catch (error) {
            const errorMessage = `deleteRecruiterUserApi API Request failed using companyId: ${companyId} and user: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async addParticipantsToGroup(
        companyId: string,
        groupId: string,
        participants: CreateUserSchema[],
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/participants`,
                'POST',
                { participants: JSON.stringify(participants) },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `addParticipantsToTargetGroup API Request failed using companyId: ${companyId},groupId: ${groupId} and participants: ${participants}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async removeParticipantsFromTargetGroup(
        companyId: string,
        groupId: string,
        userIds: string[],
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/participants`,
                'PATCH',
                {
                    operation: 'delete',
                    user_ids: userIds,
                },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `removeParticipantsFromTargetGroup API Request failed using companyId: ${companyId}, groupId: ${groupId} and userIds: ${userIds}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fullDeleteParticipantsFromTargetGroup(
        companyId: string,
        groupId: string,
        userIds: string[],
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/participants`,
                'PATCH',
                {
                    operation: 'full_delete',
                    user_ids: userIds,
                },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `fullDeleteParticipantsFromTargetGroup API Request failed using companyId: ${companyId}, groupId: ${groupId} and userIds: ${userIds}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendResetPasswordEmails(userId: string) {
        try {
            return await apiRequest(
                `emails/reset-password`,
                'POST',
                {
                    client: 'dashboard',
                    user_ids: [userId],
                },
                'application/json',
                false,
            );
        } catch (error) {
            const errorMessage = `sendResetPasswordEmail API Request failed using user ids: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendReminderEmails(
        companyId: string,
        groupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/emails`,
                'POST',
                {
                    email: 'reminder',
                    user_ids: [userId],
                    resend: true,
                },
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `sendReminderEmails API Request failed using companyId: ${companyId}, groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async sendReportEmails(companyId: string, groupId: string, userId: string) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/emails`,
                'POST',
                {
                    email: 'report',
                    user_ids: [userId],
                    resend: true,
                },
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `sendReportEmails API Request failed using companyId: ${companyId}, groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async resetCandidateScan(
        companyIdentifier: string,
        groupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyIdentifier}/groups/${groupId}/participants/${userId}`,
                'PATCH',
                {},
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `resetCandidateScan API Request failed using companyIdentifier: ${companyIdentifier}, groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async convertUserVideos(
        companyId: string,
        groupId: string,
        userId: string,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups/${groupId}/convert_videos`,
                'POST',
                {
                    user_ids: [userId],
                },
                'application/json',
                false,
            );
            return {
                response: response,
            };
        } catch (error) {
            const errorMessage = `convertUserVideos API Request failed using companyId: ${companyId} and groupId: ${groupId} and userId: ${userId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async updateTargetGroupConfig(
        companySlug: string,
        groupId: string,
        data: TargetGroupUpdate,
    ) {
        try {
            const response = await apiRequest(
                `companies/${companySlug}/groups/${groupId}/config`,
                'PUT',
                data,
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `updateTargetGroupConfig API Request failed using companyId: ${companySlug}, groupId: ${groupId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createTargetGroup(companyId: string, data: UpdateTargetGroupSchema) {
        try {
            const response = await apiRequest(
                `companies/${companyId}/groups`,
                'POST',
                data,
                'application/json',
                false,
            );
            return {
                targetGroup: response,
            };
        } catch (error) {
            const errorMessage = `createTargetGroup API Request failed using companyId: ${companyId} and data: ${data}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async createCompany(company: {
        name: string;
        country: string;
        slug: string;
        logoUrl: string;
    }): Promise<Company> {
        try {
            const response = await apiRequest(
                `companies`,
                'POST',
                company,
                'application/json',
                false,
            );
            return response ? response : {};
        } catch (error) {
            const errorMessage = `createCompany API Request failed with company info: ${company}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async uploadCompanyLogoApi(
        params: {
            type: string;
            file_name: string;
            params?: { company_slug?: string };
        },
        file: File,
    ): Promise<string | undefined> {
        try {
            const presignedUrl = await apiRequest(
                `files/upload`,
                'POST',
                params,
                'application/json',
                false,
            );
            if (presignedUrl) {
                await apiRequest(
                    presignedUrl.url,
                    'PUT',
                    file,
                    'image/png',
                    true,
                );
                await apiRequest(`files/${presignedUrl.id}`, 'PATCH');
                return presignedUrl.url.split('?')[0];
            }
        } catch (error) {
            const errorMessage = `requestUpload API Request failed when trying to upload: ${params}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanies() {
        try {
            const response = await apiRequest('companies', 'GET');
            return response && response.length ? response : [];
        } catch (error) {
            const errorMessage = `getCompanies API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getCompanyById(companyId: string) {
        try {
            const response = await apiRequest(`companies/${companyId}`, 'GET');
            return response ? response : {};
        } catch (error) {
            const errorMessage = `getCompanyById API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async fetchCompanyMediaFiles(companyId: string): Promise<MediaFile[]> {
        try {
            const response = await apiRequest(
                `companies/${companyId}/files?return_inactive=false`,
                'GET',
                {},
            );
            return response ? response : [];
        } catch (error) {
            const errorMessage = `getMediaFiles API Request failed. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }

    async getTargetGroupFeedback(companyId: string, groupId: string) {
        try {
            return await apiRequest(
                `companies/${companyId}/groups/${groupId}/feedback`,
                'GET',
            );
        } catch (error) {
            const errorMessage = `getTargetGroupFeedback API Request failed using companyId= ${companyId} groupId: ${groupId}. ${error}.`;
            Sentry.captureMessage(errorMessage);
            throw Error(errorMessage);
        }
    }
}

export default new NeurolyticsAPI();
