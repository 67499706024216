import { AppState } from '../reducers';

export const selectTargetGroupState = (state: AppState) => state.targetGroups;
export const selectTargetGroupStateError = (state: AppState) =>
    state.targetGroups.error;

export const selectTargetGroupDataById =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].data
            : null;
    };

export const selectTargetGroupWorkPreferenceBaseline =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].workPreferenceBaseline
            : null;
    };

export const selectTargetGroupFeedback =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].feedback
            : null;
    };

export const selectTargetGroupFeedbackUpdating =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].updatingFeedback
            : undefined;
    };

export const selectTargetGroupCandidateScanReset =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].resetCandidateScan
            : false;
    };

export const selectUpdatingParticipants =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].updatingParticipants
            : false;
    };

export const selectResetScanLoading =
    (groupId: string | undefined) => (state: AppState) => {
        return groupId && state.targetGroups.byId[groupId]
            ? state.targetGroups.byId[groupId].resetScanLoading
            : false;
    };

export const selectReportsNotSent = (state: AppState) =>
    state.targetGroups.reportsNotSent;
