import AppButton from 'components/AppButton/AppButton';
import { TrashIcon } from 'evergreen-ui';
import React, { FunctionComponent } from 'react';
import styles from './UsersTableFooter.module.css';
import { DashboardUser } from '../../../../typings/dashboard_user';

interface UsersTableFooterProps {
    isSuperAdmin: boolean;
    hasCompanyAdminRole: boolean;
    onDeleteRecruiterUsersEvent: () => void;
    selectedUsers: DashboardUser[];
}

const UsersTableFooter: FunctionComponent<UsersTableFooterProps> = (
    props: UsersTableFooterProps,
) => {
    const {
        isSuperAdmin,
        hasCompanyAdminRole,
        onDeleteRecruiterUsersEvent,
        selectedUsers,
    } = props;

    return (
        <div className={styles.footer}>
            <div className={styles.footer_with_dialog_buttons}>
                {(isSuperAdmin || hasCompanyAdminRole) && (
                    <AppButton
                        icon={TrashIcon}
                        color={
                            selectedUsers.length > 0 ? 'red' : 'grayDisabled'
                        }
                        onClick={onDeleteRecruiterUsersEvent}
                        isDisabled={selectedUsers.length !== 1}>
                        Remove user
                    </AppButton>
                )}
            </div>
        </div>
    );
};

export default UsersTableFooter;
